import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Pane,
  Heading,
  Paragraph,
  Avatar,
  Li,
  Ul,
  Link,
  Badge,
  toaster
} from 'evergreen-ui';
import { USER_QUERY } from './queries';
import UpdateProfileForm from './UpdateProfileForm';
import LoadingScreen from 'components/shared/LoadingScreen';
import ResetPasswordAction from './ResetPasswordAction';
import ChangeUserRole from './ChangeUserRole';
import SubscriptionDetails from './SubscriptionDetails';

const UserProfile = ({ userId }: any) => {
  const { data, loading } = useQuery(USER_QUERY, {
    variables: { userId },
    fetchPolicy: 'network-only'
  });

  const user = get(data, 'user', {});
  const userSeats = get(user, 'seats', []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Pane>
      <Pane
        padding={16}
        background="tint2"
        borderBottom
        display="flex"
        alignItems="center"
      >
        <Avatar name={user.name} size={40} />
        <Pane marginLeft={16}>
          <Heading size={500}>{user.name}</Heading>
          <Paragraph size={400} color="muted">
            {user.email}
          </Paragraph>
        </Pane>
        {user.role && (
          <Badge color="neutral" marginLeft="auto">
            {user.role}
          </Badge>
        )}
      </Pane>
      <Pane padding={16}>
        <Pane marginBottom={24} borderBottom paddingBottom={16}>
          <Heading size={500} marginBottom={16}>
            Update user profile
          </Heading>

          <UpdateProfileForm
            user={user}
            onComplete={() => {
              toaster.success('User successfully updated');
            }}
          />
        </Pane>
        <Pane marginBottom={24} borderBottom paddingBottom={16}>
          <Heading size={500} marginBottom={16}>
            Actions
          </Heading>

          <Pane marginTop={8}>
            <ResetPasswordAction
              userId={userId}
              onComplete={() => {
                toaster.success(
                  'Reset password email has been sent to the user'
                );
              }}
            />
          </Pane>
        </Pane>

        <Pane marginBottom={24} paddingBottom={16} borderBottom>
          <Heading size={500} marginBottom={16}>
            Organizations
          </Heading>
          <Ul>
            {userSeats.map((seat: any) => {
              return (
                <Li key={seat.id}>
                  <Link href={`/orgs/${seat.organization.slug}`}>
                    {seat.organization.name}
                  </Link>
                </Li>
              );
            })}

            {userSeats.length === 0 && (
              <Li color="muted">
                Does not currently belong to any organizations
              </Li>
            )}
          </Ul>
        </Pane>

        {user.subscription && (
          <Pane marginBottom={24} paddingBottom={16} borderBottom>
            <Heading size={500} marginBottom={8}>
              Subscription
            </Heading>

            <SubscriptionDetails subscription={user.subscription} user={user} />
          </Pane>
        )}

        <Pane marginBottom={24} paddingBottom={16} borderBottom>
          <Heading size={500} marginBottom={16}>
            Change users system role
          </Heading>
          <Paragraph color="muted" marginBottom={16}>
            Warning: only do this when the user should have access to the Muru
            admin system. Generally this should *only* be for murumed.com folks.
          </Paragraph>

          <ChangeUserRole
            user={user}
            onComplete={() => {
              toaster.success('Users system role changed');
            }}
          />
        </Pane>
      </Pane>
    </Pane>
  );
};

export default UserProfile;
