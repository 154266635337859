import React, { useEffect, useState } from 'react';
import { Pane, Paragraph, Spinner, Button } from 'evergreen-ui';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { currentUserSelector } from 'redux/global';
import { useSelector } from 'react-redux';
import { get, groupBy } from 'lodash';
import queryString from 'query-string';
import { SUBSCRIPTIONS } from './queries';
import SubscriptionSummary from './SubscriptionSummary';
import IndividualPlanDetails from './IndividualPlanDetails';
import SectionCard from './SectionCard';
import AddOrganizationFlow from 'components/accounts/AddOrganizationFlow';
import { SubscriptionFragment } from 'components/shared/fragments';
import { orgPath } from 'lib/urls';

const SubscriptionsSummary = () => {
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();
  const params = queryString.parse(location.search);
  const [showAddDialog, setShowAddDialog] = useState(false);

  const currentUser = useSelector(currentUserSelector);
  const { data, loading } = useQuery(SUBSCRIPTIONS, {
    fetchPolicy: 'network-only'
  });

  // We read the subscription from the apollo cache because
  // it might be stale in redux
  // TODO: we need to fix this by stopping to read the current user
  // from the redux cache
  const subscription =
    currentUser.subscription &&
    client.readFragment({
      id: `PaymentSubscription:${currentUser.subscription.id}`,
      fragment: SubscriptionFragment
    });

  useEffect(() => {
    // When the current user does not have a subscription then
    // we want to take the user to the onboarding flow and convert them
    // there as they will be a new individual subscriber
    if (!subscription && params['add-org']) {
      history.push('/onboarding');
      return;
    }

    // Otherwise show the modal over everything if the param is set
    setShowAddDialog(!!params['add-org']);
  }, [params, subscription, history, setShowAddDialog]);

  if (loading) {
    return (
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={16}
      >
        <Spinner size={20} />
      </Pane>
    );
  }

  const seats = get(data, 'currentUser.seats', []);
  const groupedSeats = groupBy(seats, seat =>
    seat.subscription ? 'individual' : 'organization'
  );

  const individualSeats = get(groupedSeats, 'individual', []);
  const organizationSeats = get(groupedSeats, 'organization', []);

  return (
    <>
      <SectionCard title="Your subscription">
        {individualSeats.length === 0 ? (
          <Pane textAlign="center" background="tint1" padding={32}>
            <Pane width={300} marginLeft="auto" marginRight="auto">
              <Paragraph textAlign="center" color="muted">
                You don't currently have any active agencies that you belong to
                in Muru.
              </Paragraph>

              <Button
                is={RouterLink}
                to="/onboarding"
                marginTop={16}
                appearance="primary"
              >
                Add an agency
              </Button>
            </Pane>
          </Pane>
        ) : (
          <IndividualPlanDetails
            seats={individualSeats}
            subscription={subscription}
            onAddClick={() => history.push('/account?add-org=true')}
          />
        )}
      </SectionCard>
      <SectionCard title="Paid agency memberships">
        {organizationSeats.length === 0 ? (
          <>
            <Pane textAlign="center" background="tint1" padding={32}>
              <Pane width={300} marginLeft="auto" marginRight="auto">
                <Paragraph textAlign="center" color="muted" marginBottom={8}>
                  You're currently not part of any agencies that are paying for
                  your access to Muru.
                </Paragraph>
              </Pane>
            </Pane>
          </>
        ) : (
          <Pane paddingX={16}>
            <Pane borderBottom="muted">
              {organizationSeats.map(seat => (
                <SubscriptionSummary
                  key={seat.id}
                  seat={seat}
                  onViewClick={() => history.push(orgPath(seat.organization))}
                />
              ))}
            </Pane>

            <Paragraph
              textAlign="center"
              size={300}
              color="muted"
              marginTop={16}
              marginBottom={16}
              marginLeft="auto"
              marginRight="auto"
              maxWidth={360}
              display="block"
            >
              These are the agencies that you're a member of, but are not paying
              to access. If you need to remove an agency in this list, please
              speak to the administrator there.
            </Paragraph>
          </Pane>
        )}
      </SectionCard>

      <AddOrganizationFlow
        isShown={showAddDialog}
        onClose={() => history.push('/account')}
        seats={individualSeats}
        subscription={subscription}
        goToWaitlist={() => history.push('/onboarding/waitlist')}
      />
    </>
  );
};

export default SubscriptionsSummary;
